import React from 'react';
import Table from 'cccisd-graphql-table';
import DateRender from './DateRender';
import Status from './Status';
import style from './style.css';
import coachQuery from './coachQuery.graphql';

const Appdefs = window.cccisd.appDefs;

const Progress = () => {
    let instructorLabel = 'Provider';
    try {
        instructorLabel = Appdefs.pawn.roles.find(r => r.handle === 'instructor').label || 'Provider';
    } catch (e) {} // eslint-disable-line no-empty

    const colProps = {
        sort: true,
        filter: true,
    };
    const dateProps = {
        filterSettings: {
            type: 'date',
        },
        render: DateRender,
    };

    const renderInstructorProgress = () => (
        <div className={style.table} key="coach">
            <Table
                name={instructorLabel}
                query={coachQuery}
                rowKey="pawn.pawnId"
                columns={[
                    { ...colProps, name: 'user.username', label: 'Username' },
                    { ...colProps, name: 'user.firstName', label: 'First Name' },
                    { ...colProps, name: 'user.lastName', label: 'Last Name' },
                    {
                        ...colProps,
                        name: 'assignmentProgress.status',
                        label: 'Status',
                        render: Status,
                    },
                    {
                        ...colProps,
                        ...dateProps,
                        name: 'assignmentProgress.startedAtDate',
                        label: 'Started',
                    },
                    {
                        ...colProps,
                        ...dateProps,
                        name: 'assignmentProgress.completedAtDate',
                        label: 'Completed',
                    },
                    {
                        ...colProps,
                        ...dateProps,
                        name: 'user.lastLoginDate',
                        label: 'Last Login',
                    },
                ]}
            />
        </div>
    );

    return <div className={style.pageWrap}>{renderInstructorProgress()}</div>;
};

export default Progress;
